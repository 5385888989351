import { tint } from 'polished';
import { IS_STAGE, IS_UAT } from 'helpers';

// Change the colors for your personal preference
const COLOR_PRIMARY_PROD = '#426B42';
const COLOR_PRIMARY_STAGE = '#2abeef';
const COLOR_PRIMARY_UAT = '#4d303b';

const getPrimaryColor = (): string => {
  if (IS_STAGE) return COLOR_PRIMARY_STAGE;
  if (IS_UAT) return COLOR_PRIMARY_UAT;
  return COLOR_PRIMARY_PROD;
};

const primaryColor = getPrimaryColor();
const tintedPrimaryColor = tint(0.15, primaryColor);
const secondaryColor = '#DCE5B3';
const tintedSecondaryColor = tint(0.15, secondaryColor);
const ternaryColor = '#F0F0F0';
const tintedTernaryColor = tint(0.15, ternaryColor);
const buttonColor = '#48b4ca';
const dangerColor = '#dc0818';
const warningColor = '#ffc107';

export const theme = {
  primaryColor,
  tintedPrimaryColor,
  secondaryColor,
  tintedSecondaryColor,
  ternaryColor,
  tintedTernaryColor,
  buttonColor,
  dangerColor,
  warningColor,
};
