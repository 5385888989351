export default {
  nav: {
    main: {
      assets: 'Stamgegevens',
      operations: 'Operatie',
      kpn: 'KPN',
      telemetry: 'Telemetrie',
      support: 'Hulp',
    },
    telemetry: {
      lppRecord: 'Lpp Records',
    },
    kpn: {
      kpnDevices: 'Apparaten',
      uplink: 'Uplinks',
      downlink: 'Downlinks',
      processedRLCUplink: 'Processed uplinks',
    },
    operations: {
      devices: 'Apparaten',
      firmwareVersion: 'Firmware versie',
      hardwareVersion: 'Hardware versie',
      batch: 'RLC oplage',
      rainBarrel: 'Regenton',
      inventory: 'Voorraad Systeem',
      roof: 'Roefs',
    },
    assets: {
      users: 'Gebruikers',
      globalValues: 'Instellingen',
      globalFiles: 'Bijzondere bestanden',
      emailTemplates: 'Email sjablonen',
      userGuides: 'Gebruikershandleiding',
    },
    audit: {
      accessLog: 'Toegangslog',
    },
    account: {
      account: 'Account',
      changelog: 'Changelog'
    },
    errors: {
      kpnMockErrorAdmin: 'KPN MOCK staat aan. Als dit een productie server is, zet KPN MOCK zo snel mogelijk uit',
      kpnMockErrorOperator: 'KPN integratie is niet beschikbaar, contacteer onze hulplijn',
    },
  },
  common: {
    pagination: {
      limit: 'Aantal per pagina',
    }
  },
  user: {
    overview: {
      title: 'Gebruikers',
      addButton: 'Nieuwe gebruiker',
    },
    create: {
      title: 'Gebruiker aanmaken',
    },
    edit: {
      title: 'Gebruiker aanpassen',
    },
    field: {
      fullName: { label: 'Naam' },
      firstName: { label: 'Voornaam' },
      lastName: { label: 'Achternaam' },
      email: { label: 'E-mail' },
      language: { label: 'Taal' },
      password: { label: 'Wachtwoord' },
      dateJoined: { label: 'Toegevoegd Op' },
      groups: {
        label: 'Groepen',
        value: {
          superuser: 'Supergebruiker',
          admin: 'Administrator',
          manager: 'Manager',
          operator: 'Operator',
          external_api: 'API',
          production: 'Productie',
        },
        empty: 'Geen groepen toegekend',
      },
      isActive: {
        label: 'Users',
        options: {
          all: 'All',
          yes: 'Active',
          no: 'Not Active',
        }
      },
      mfa: {
        label: 'MFA-methoden',
      },
    },
    login: {
      title: 'Inloggen',
      forgotPasswordLink: 'Wachtwoord vergeten?',
      errors: {
        invalidCredentials: 'E-mail of wachtwoord is niet correct',
        unknown: 'Onbekende fout, status code: {{status}}',
      },
      loginButton: 'Inloggen',
    },
    passwordForgot: {
      title: 'Wachtwoord Vergeten',
      requestButton: 'Verstuur Email',
      requestedEmailText:
        'Er is een email met een wachtwoord reset code verzonden naar uw email adres.',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord vergeten aanvragen als je uitgelogd bent.',
    },
    resetPassword: {
      title: 'Wachtwoord Reset',
      resetButton: 'Reset',
      saveSuccess: 'Wachtwoord is succesvol gereset',
      loggedInError: 'Je bent ingelogd. Je kunt alleen wachtwoord resetten als je uitgelogd bent.',
    },
    changePassword: {
      title: 'Wachtwoord Aanpassen',
      field: {
        passwordOld: {
          label: 'Huidig wachtwoord',
        },
        passwordNew: {
          label: 'Nieuw wachtwoord',
        },
      },
      saveButton: 'Opslaan',
    },
    account: {
      title: 'Profiel',
      logoutButton: 'Uitloggen',
      info: {
        title: 'Gegevens',
      },
      password: {
        title: 'Wachtwoord Veranderen',
      },
      logout: {
        title: 'Uitloggen',
        confirm: 'Weet u zeker dat u wilt uitloggen?'
      },
    },
    multiFactorAuth: {
      code: {
        app: 'Verificatiecode',
        email: 'Inlogcode',
        newCode: 'Nieuwe code aanvragen',
      },
      expiring: 'Je authenticatie code is nog {{ minutes }} minuten geldig',
      explanation: {
        app: 'Voer de verificatiecode in die uw verificatie-app voor u heeft gegenereerd.',
        email: 'Uw inlogcode is per e-mail naar u verzonden.',
      },
      explanationFailed: 'We hebben geprobeerd een inlogcode naar het geregistreerde emailadres te sturen, maar dit is mislukt.',
      timeout: 'U hebt het te vaak geprobeerd. U kunt het over een paar minuten opnieuw proberen.',
      invalid: {
        app: 'De verificatiecode is verkeerd of is verlopen. Probeer het opnieuw.',
        email: 'De inlogcode is verkeerd of is verlopen. Probeer het opnieuw of vraag een nieuwe code aan.',
      },
      resend: 'Verstuur opnieuw',
      title: 'Bevestig',
    },
  },
  myFilter: {
    custom: 'Nieuw',
    action: {
      unsetDefault: 'Gebruik niet als standaard',
      setDefault: 'Gebruik als standaard',
      setCurrent: 'Sla huidige filters op',
      delete: 'Verwijderen',
    },
  },
  filters: {
    deletedFilter: {
      label: 'Verwijderd',
      withoutDeleted: 'Zonder verwijderd',
      onlyDeleted: 'Alleen verwijderd',
      bothDeletedAndNot: 'Beide'
    },
    notesFilter: {
      label: 'Notities',
      withoutNotes: 'Zonder notities',
      onlyNotes: 'Alleen notities',
      bothNotesAndNot: 'Beide'
    },
    statusFilter: {
      deactivated: 'Niet geactiveerd',
      none: 'Geen berichten',
      red: 'Rood',
      orange: 'Oranje',
      green: 'Groen',
    },
  },
  globalValue: {
    edit: {
      title: 'Waarde wijzigen van algemene instelling',
    },
    overview: {
      title: 'Algemene instellingen',
    },
    field: {
      key: {
        label: 'Naam instelling',
      },
      value: {
        label: 'Waarde',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  globalFile: {
    edit: {
      title: 'Bijzonder bestand bewerken',
    },
    overview: {
      title: 'Bijzondere bestanden',
    },
    field: {
      key: {
        label: 'Naam voor bestand',
      },
      value: {
        label: 'Bestand',
      },
      writable: {
        label: 'Schrijfbaar?',
      },
      readable: {
        label: 'Leesbaar?',
      },
    },
  },
  accessLogEntry: {
    overview: {
      title: 'Auditlog',
    },
    field: {
      originalUser: {
        label: 'Oorspronkelijke gebruiker',
      },
      masqueradingAsUser: {
        label: 'Gemaskeerd als gebruiker',
      },
      ipAddress: {
        label: 'IP-adres',
      },
      method: {
        label: 'HTTP-methode',
      },
      endpoint: {
        label: 'Endpoint',
      },
      statusCode: {
        label: 'Statuscode',
      },
      additionalInfo: {
        label: 'Extra informatie',
      },
      requestTime: {
        label: 'Tijdstip van toegang',
      },
    },
  },
  tooltips: {
    masquerade: 'Voordoen als',
    edit: 'Aanpassen',
    delete: 'Verwijderen',
    download: 'download',
    view: 'Bekijk',
    restore: 'Herstel',
  },
  form: {
    search: 'Zoeken',
    actions: 'Acties',
    repeat: 'Herhaal',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Anders',
      none: 'Nog geen bestand geüpload',
    },
    saveButton: 'Opslaan',
    multiPick: {
      searchPlaceholder: 'Selecteer…',
      selectedText: '$1 uit $2 geselecteerd',
      noneSelectedText: 'Geen geselecteerd',
      selectAllButton: 'Alle',
      selectNoneButton: 'Geen',
    },
    notifications: {
      saveSuccess: 'Succesvol opgeslagen',
      deleteSuccess: 'Succesvol verwijderd',
      saveError: 'Fout met opslaan ({{status}})',
      saveValError: 'Niet alle informatie is correct ingevuld',
      saveAuthError: 'Niet geauthorizeerd',
      newAppVersion:
        'Nieuwe versie beschikbaar, klik om de pagina te verversen',
    },
    submitButton: 'Verstuur',
    cancelButton: 'Annuleren',
    closeButton: 'Sluiten',
    sendButton: 'Verstuur',
    addButton: 'Toevoegen',
    deleteConfirmation: 'Weet u zeker dat u dit wilt verwijderen?',
    restoreConfirmation: 'Weet u zeker dat u dit wilt herstellen?',
    startDate: 'Start Datum',
    endDate: 'Eind Datum',
    no: 'Nee',
    yes: 'Ja',
    either: 'Beide',
    failed: 'Gefaald',
    success: 'Geslaagd',
    warning: 'Let op',
    processing: 'Bezig met verwerken',
    hide: 'Verstop',
    show: 'Toon',
    instruction: 'Instructie',
    saveAndBackButton: 'Opslaan en terug',
    saveAndNewButton: 'Opslaan en nieuw',
  },
  crash: {
    heading: 'Applicatie gecrasht',
    subHeading1: 'Onze excuses, maar de applicatie is gecrasht.',
    subHeading2: 'Ons team is op de hoogte gesteld.',
    reload: 'Herlaad pagina'
  },
  emailTemplate: {
    overview: {
      title: 'Email sjablonen',
      addButton: 'Nieuw sjabloon',
    },
    create: { title: 'Mail sjabloon aanmaken' },
    edit: { title: 'Mail sjabloon aanpassen' },
    add: { title: 'Mail sjabloon toevoegen' },
    field: {
      id: { label: 'ID' },
      isDefault: { label: 'Is standaard' },
      name: { label: 'Naam' },
      emailType: { label: 'Email type' },
      subject: { label: 'Onderwerp' },
      textContent: { label: 'Text variant van email', placeholder: 'Sommige mail clients supporten alleen tekst' },
      senderEmail: { label: 'Verzender' },
    }
  },
  emailTypes: {
    password_reset: 'Wachtwoord reset',
    user_activation: 'Account activatie',
  },
  authentication: {
    dates: {
      maxDate: {
        placeholder: 'Max date'
      },
      minDate: {
        placeholder: 'Min date'
      },
      month: {
        0: 'January',
        1: 'February',
        10: 'November',
        11: 'December',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October'
      },
      weekDayShort: {
        friday: 'Fr',
        monday: 'Mo',
        saturday: 'Sa',
        sunday: 'Su',
        thursday: 'Th',
        tuesday: 'Tu',
        wednesday: 'We'
      }
    },
    field: {
      mfa: {
        label: 'Methode',
      },
      code: {
        label: 'Code'
      },
      expiresAt: {
        label: 'Verloopt Op'
      },
      ipAddress: {
        label: 'IP-adres'
      },
      status: {
        label: 'Status',
        value: {
          expired: 'Verlopen',
          verified: 'Geverifieerd',
          waiting: 'In behandeling'
        }
      },
      userAgent: {
        label: 'User Agent'
      }
    }
  },
  multiFactorAuthentication: {
    setup: {
      title: 'Multistapsverificatie instellen',
      step1: {
        title: '1. Download de app',
        description: 'Download een verificatie-app, zoals Google Authenticator, als u deze nog niet heeft.',
      },
      step2: {
        title: '2. Scan de QR-code',
        description: 'Scan deze QR-code met uw verificatie-app.',
      },
      step3: {
        title: '3. Voer uw code in',
        description: 'Voer hier de bevestigingscode in die u ziet in uw verificatie-app.',
      },
      subtitle: 'Na het instellen kunt u met uw verificatie-app altijd veilig inloggen.',
    },
    field: {
      method: {
        label: 'MFA-methode',
        options: {
          app: 'Verificatie-app',
          email: 'E-mail'
        }
      },
      counter: {
        label: 'Gebruik',
      },
      activated: {
        label: 'Geverifieerd',
      },
    }
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{weekNumber}}',
    },
    weekDay: {
      monday: 'Ma',
      tuesday: 'Di',
      wednesday: 'Wo',
      thursday: 'Do',
      friday: 'Vr',
      saturday: 'Za',
      sunday: 'Zo',
    },
    month: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maart',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Augustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
  },
  roof: {
    overview: {
      title: 'Roefs',
      resultsTitle: 'Roefs zoekresultaten',
    },
    create: { title: 'Nieuw Roef toevoegen' },
    edit: { title: 'Bewerk Roef', tooltip: 'Bewerk Roef' },
    field: {
      id: { label: 'ID' },
      referenceNumber: { label: 'Referentienummer' },
      orientation: { label: 'Windrichting voorkant' },
      rainBarrel: { label: 'Regenton' },
      rlcStatus: { label: 'RLC Status' },
      device: { label: 'RLC ID' },
      notes: { label: 'Notities' },
    },
    windDirection: {
      north: 'Noord',
      northNortheast: 'Noord-Noordoost',
      northeast: 'Noordoost',
      eastNortheast: 'Oost-Noordoost',
      east: 'Oost',
      eastSoutheast: 'Oost-Zuidoost',
      southeast: 'Zuidoost',
      southSoutheast: 'Zuid-Zuidoost',
      south: 'Zuid',
      southSouthwest: 'Zuid-Zuidwest',
      southwest: 'Zuidwest',
      westSouthwest: 'West-Zuidwest',
      west: 'West',
      westNorthwest: 'West-Noordwest',
      northwest: 'Noordwest',
      northNorthwest: 'Noord-Noordwest',
    },
    view: {
      title: 'Roef',
      home: 'Home',
      general: 'Algemeen',
      solar: 'Zon',
      water: 'Water',
      biodiversity: 'Biodiversiteit',
      maintenance: 'Onderhoud',
      charts: 'Data',
      rlcOverview: {
        label: 'RLC Overzicht',
        noDeviceAssignedMessage: 'Er is nog geen RLC aan dit Roef toegekend. Gebruik de Bewerk Roef knop in de taakbalk hieronder om een RLC toe te voegen.',
      },
    },
    filters: {
      deviceAssigned: { label: 'RLC gekoppeld aan Roef', true: 'Nee', false: 'Ja', both: 'Beide' },
    },
  },
  batch: {
    overview: {
      title: 'RLC oplage',
      resultsTitle: 'RLC oplage zoekresultaten',
    },
    nav: {
      overview: 'RLC oplage'
    },
    field: {
      id: { label: 'ID' },
      label: { label: 'Naam' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg RLC oplage toe',
    },
    createButton: 'Voeg nieuwe RLC oplage toe',
  },
  hardwareVersion: {
    overview: {
      title: 'Hardware versies',
      resultsTitle: 'Hardware versies zoekresultaten',
    },
    nav: {
      overview: 'Hardware versies'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg hardware versie toe'
    },
    delete: {
      error: 'Kon hardware versie niet weghalen',
      success: 'Hardware versie verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk hardware versie'
    },
    createButton: 'Voeg nieuwe hardware versie toe',
    editTooltip: 'Bewerk',
  },
  rainBarrel: {
    overview: {
      title: 'Regentonnen',
      resultsTitle: 'Regentonnen zoekresultaten',
    },
    nav: {
      overview: 'Regentonnen'
    },
    field: {
      id: { label: 'ID' },
      name: { label: 'Naam' },
      width: { label: 'Breedte (mm)' },
      height: { label: 'Hoogte (mm)' },
      depth: { label: 'Diepte (mm)' },
      volume: { label: 'Volume (m³)' },
      lowerBoundNormalization: { label: 'Ondergrens druksensor' },
      upperBoundNormalization: { label: 'Bovengrens druksensor' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Regenton toevoegen'
    },
    delete: {
      error: 'Kon regenton niet verwijderen',
      success: 'Regenton verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Regenton bewerken',
      normalizationInstructionLower: 'De ondergrens waarde kan bepaald worden door genoeg water in de regenton te doen zodat het Roef gesproeid wordt wanneer de pomp aan staat. Laat de pomp aanstaan tot er geen water meer uit de sproeislang komt. Zet de pomp uit. Wacht tot de RLC een nieuw bericht heeft gestuurd met de druksensor waarde. De druksensor waarde van dit laatste bericht moet worden ingevuld als ondergrens.',
      normalizationInstructionUpper: 'De bovengrens waarde kan bepaald worden door de regenton volledig te vullen met water totdat het water uit de overloop loopt. Stop met vullen en wacht tot het water niet meer uit de overloop loopt. Wacht tot de RLC een nieuw bericht heeft gestuurd met de druksensor waarde. De druksensor waarde van dit laatste bericht moet worden ingevuld als ondergrens.',
    },
    createButton: 'Voeg nieuwe regenton toe',
    editTooltip: 'Regenton bewerken',
  },
  firmwareVersion: {
    overview: {
      title: 'Firmware versies',
      resultsTitle: 'Firmware versies zoekresultaten',
    },
    nav: {
      overview: 'Firmware versies'
    },
    field: {
      id: { label: 'ID' },
      version: { label: 'Versie' },
      link: { label: 'Link' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
    },
    create: {
      title: 'Voeg firmware versie toe'
    },
    delete: {
      error: 'Kon firmware versie niet verwijderen',
      success: 'Firmware versie verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk firmware versie'
    },
    createButton: 'Voeg nieuwe firmware versie toe',
    editTooltip: 'Bewerk firmware versie',
  },
  device: {
    overview: {
      title: 'Apparaten',
      resultsTitle: 'Apparaten zoekresultaten',
    },
    nav: {
      overview: 'Apparaten'
    },
    field: {
      id: { label: 'ID' },
      cohort: { label: 'Cohort' },
      devui: { label: 'Apparaat ID' },
      appkey: { label: 'Applicatie sleutel' },
      firmwareVersion: { label: 'FW versie' },
      hardwareVersion: { label: 'HW versie' },
      batch: { label: 'RLC oplage' },
      rainBarrel: { label: 'Regenton' },
      operationalStatus: { label: 'Operationele status' },
      group: { label: 'Groep' },
      identifier: { label: 'Identifier' },
      createdAt: { label: 'Aangemaakt op' },
      notes: { label: 'Notities' },
      shipped: { label: 'Geleverd' },
      lastMessageAt: { label: 'Status' },
      decoder: { label: 'Decoder' },
      selectionValveFlipped: { label: 'Beregeningsklep orientatie wisselen', explanation: 'Zet deze knop om als de klep verkeerd om is aangesloten.' },
      isInFlow: { label: 'Apparaat activatie status' },
      roof: { label: 'Roef ref. nr.', filter: 'Roef referentie nummer' },
    },
    create: {
      title: 'Voeg apparaat toe'
    },
    delete: {
      error: 'Kon apparaat niet verwijderen ',
      success: 'Apparaat verwijderd'
    },
    edit: {
      pagesTitle: 'Paginas',
      title: 'Bewerk apparaat',
      warningNotActive: { label: 'Apparaat is niet geactiveerd!', explanation: 'Let op, dit apparaat is nog niet geactiveerd en zal geen berichten versturen totdat het apparaat geactiveerd is. Gebruik onderstaande knop om de activatie status van het apparaat te wijzigen.' },
    },
    bulkActions: {
      error: {
        noRainBarrel: 'Geen regenton geconfigureerd.',
        notInFlow: 'Apparaat is niet geactiveerd.',
        unknown: 'Een onbekende fout is opgetreden',
        incorrectInformation: 'De informatie is niet juist ingevuld',
        processing: 'Een fout is opgetreden bij het verwerken van de bulk actie',
      },
      modalTitles: {
        bulkControlPump: 'Bulk aansturen pomp',
        bulkControlDumpValve: 'Bulk aansturen stortklep',
      },
      results: {
        successfullySent: 'Verzenden geslaagd bij ',
        successfulDownlinks: 'Geslaagde Downlinks',
        failedSent: 'Verzenden gefaald bij ',
        failedDownlinks: 'Gefaalde Downlinks',
      },
      chooseAction: 'Selecteer bulk actie',
      changeOperationalStatus: 'Wijzig operationele status',
      changeGroup: 'Wijzig groep',
      changeFirmware: 'Wijzig firmware versie',
      changeHardware: ' Wijzig hardware versie',
      changeBatch: 'Wijzig RLC oplage',
      changeRainBarrel: 'Wijzig regenton',
      controlPump: 'Aansturen pomp',
      controlValve: 'Aansturen stortklep',
    },
    messageStatus: {
      notAssigned: 'Geen RLC aan Roef gekoppeld',
      deactivated: 'RLC is niet geactiveerd',
      none: 'Nog geen berichten ontvangen',
      green: 'Laatste bericht minder dan 30 minuten geleden',
      orange: 'Laatste bericht meer dan 30 minuten geleden',
      red: 'Laatste bericht meer dan 3 uur geleden',
    },
    createButton: 'Voeg apparaat toe',
    editTooltip: 'Bewerk apparaat',
  },
  rlcDeviceConfig: {
    field: {
      aIn1Logging: { label: 'Vochtsensor 1', info: 'Kies of Vochtsensor 1 gelogd wordt' },
      aIn2Logging: { label: 'Vochtsensor 2', info: 'Kies of Vochtsensor 2 gelogd wordt' },
      aIn3Logging: { label: 'Vochtsensor 3', info: 'Kies of Vochtsensor 3 gelogd wordt' },
      aIn4Logging: { label: 'Vochtsensor 4', info: 'Kies of Vochtsensor 4 gelogd wordt' },
      aIn5Logging: { label: 'Druksensor', info: 'Kies of Druksensor gelogd wordt' },
      dIo1Logging: { label: 'Raamsensor 1', info: 'Kies of Raamsensor 1 gelogd wordt' },
      dIo2Logging: { label: 'Raamsensor 2', info: 'Kies of Raamsensor 2 gelogd wordt' },
      dIo3Logging: { label: 'DIO3', info: 'Kies of DIO3 value gelogd wordt' },
      dIo4Logging: { label: 'DIO4', info: 'Kies of DIO4 value gelogd wordt' },
      dIo5Logging: { label: 'Temperatuursensor 1', info: 'Kies of Temperatuursensor 1 gelogd wordt' },
      dIo6Logging: { label: 'Temperatuursensor 2', info: 'Kies of Temperatuursensor 2 gelogd wordt' },
      relay1Logging: { label: 'Pomp status', info: 'Kies of Pomp status gelogd wordt' },
      relay2Logging: { label: 'Stortklep status', info: 'Kies of Stortklep status gelogd wordt' },
      relay3Logging: { label: 'Beregeningsklep status', info: 'Kies of Beregeningsklep status gelogd wordt' },
      relay4Logging: { label: 'Relay4', info: 'Kies of Relay4 value gelogd wordt' },
    },
    edit: {
      title: 'Bewerk sensor logging voor apparaat: ',
      warning: { label: 'Wees voorzichtig met deze instellingen!', explanation: 'Let op, sensoren die niet aan staan worden niet gelogd. Sensor data die wordt ontvangen terwijl een sensor uitgevinkt is, wordt niet opgeslagen en is niet later te herstellen.' },
    },
  },
  uplink: {
    overview: {
      title: 'Uplinks',
      prossedTitle: 'Processed uplinks',
      resultsTitle: 'Uplink zoekresultaten'
    },
    field: {
      id: { label: 'ID' },
      payload: { label: 'Payload' },
      receivedAt: { label: 'Ontvangen op' },
      processedAt: { label: 'Verwerkt op' },
      decoder: { label: 'Decoder' },
    },
    filters: {
      pumpStatus: { label: 'Pomp status' },
      dumpValveStatus: { label: 'Stortklep status' },
      selectionValveStatus: { label: 'Beregeningsklep status' },
      window1Status: { label: 'Raam 1 status' },
      window2Status: { label: 'Raam 2 status' },
    }
  },
  downlink: {
    overview: {
      title: 'Downlinks',
      resultsTitle: 'Downlink zoekresultaten',
    },
    field: {
      id: { label: 'ID' },
      device: { label: 'Apparaat ID' },
      payload: { label: 'Payload' },
      status: { label: 'Status' },
      failureReason: { label: 'Reden falen' },
      sentAt: { label: 'Verzonden op' },
      createdAt: { label: 'Aangemaakt op' },

      pumpActivated: { label: 'Pomp activeren' },
      pumpDuration: { label: 'Duur in seconden (1 - 3600)', infoLabel: 'Duur in seconden' },
      pumpChoice: { label: 'Pomp keuze' },

      selectionValveActivated: { label: 'Beregeningsklep activeren' },
      selectionValveChoice: { label: 'Beregeningsklep keuze' },
      selectionValveFlipped: { label: 'Beregeningsklep omgedraaid' },

      dumpValveDuration: { label: 'Open stort klep voor x seconden', infoLabel: 'Duur in seconden' },
      dumpValveChoice: { label: 'Stortklep keuze' },
      dumpValveActivated: { label: 'Stortklep activeren' },

      scheduledActivated: { label: 'Downlink inplannen' },
      scheduledAt: { label: 'Geplande tijd' }
    },
    statusOptions: {
      queued: 'In wachtrij',
      sent: 'Verzonden',
      failed: 'Gefaald',
      success: 'Geslaagd',
    },
    control: {
      pump: 'Pomp aansturen',
      dump_valve: 'Stortklep aansturen',
      sendDownlink: 'Stuur downlink',
    },
    modalTitles: {
      sendDownlink: 'Stuur downlink naar apparaat',
      controlPump: 'Stuur pomp aan van apparaat',
      controlDump: 'Stuur stortklep aan van apparaat',
      downlinkInfo: 'Downlink informatie',
      scheduledDownlinks: 'Geplande downlinks',
    },
    tooltip: {
      errorCannotSendDownlink: 'Sturen van downlinks momenteel niet beschikbaar',
    },
    error: {
      warningNoRainBarrel: 'Sommige geselecteerde apparaten hebben geen regenton geconfigureerd. Er zullen geen downlinks naar deze apparaten verzonden worden'
    },
    button: {
      details: 'Bekijk details',
    },
  },
  operatorView: {
    overview: {
      title: 'Beheerders overzicht',
    },
    detailView: {
      title: 'Apparaat',
      errorDevuiNotAvailable: 'Apparaat ID nog niet beschikbaar',
      copyToCsv: 'Kopieer naar CSV',
      copyDevui: 'Kopieer apparaat ID AT commando',
      copyAppkey: 'Kopieer applicatie sleutel AT commando',
      warningNotActive: { label: 'Apparaat is niet geactiveerd!', explanation: 'Let op, dit apparaat is nog niet geactiveerd en zal geen berichten versturen totdat het apparaat geactiveerd is. Gebruik de bewerk apparaat knop om de activatie status van het apparaat te wijzigen.' },
    },
    peripherals: {
      title: 'Sensoren en actuatoren',
      lastMessage: 'Laatste ontvangen bericht',
      noMessage: 'Nog geen berichten ontvangen!',
      window1: 'Raam 1',
      window2: 'Raam 2',
      pump: 'Pomp',
      dumpValve: 'Stortklep',
      selectionValve: 'Beregeningsklep',
      statusOpen: 'Open',
      statusClosed: 'Gesloten',
      statusOn: 'Aan',
      statusOff: 'Uit',
      statusFront: 'Voorzijde',
      statusBack: 'Achterzijde',
    },
    moistureChart: {
      title: 'Vocht en druksensoren',
      hours24: 'Afgelopen 24 uur',
      days7: 'Afgelopen 7 dagen',
      days14: 'Afgelopen 14 dagen',
      days30: 'Afgelopen 30 dagen',
      selectDateRange: 'Selecteer aantal dagen',
      editSensorLogging: 'Bewerk sensor logging',
      moistureSensor1: 'Vochtsensor 1',
      moistureSensor2: 'Vochtsensor 2',
      moistureSensor3: 'Vochtsensor 3',
      moistureSensor4: 'Vochtsensor 4',
      pressureSensor: 'Druksensor',
      rainBarrelContent: 'Regenton inhoud',
      indicatorWet: 'Nat',
      indicatorDry: 'Droog',
    },
    downlinkChart: {
      title: 'Downlinks',
      tooltipDownlinkClick: 'Click for downlink info',
    },
    peripheralChart: {
      title: 'Sensoren en actuatoren'
    },
    temperatureChart: {
      title: 'Temperatuur sensoren',
      temperatureSensor1: 'Temperatuur sensor 1',
      temperatureSensor2: 'Temperatuur sensor 2',
    },
    tooltip: {
      loadingDevui: 'Aan het wachten tot apparaat ID beschikbaar is via KPN',
      loadingAppkey: 'Aan het wachten tot applicatie sleutel beschikbaar is via KPN',
    }
  },
  userGuide: {
    overview: {
      title: 'Gebruikershandleidingen',
      resultsTitle: 'Gebruikershandleidingen',
    },
    field: {
      id: { label: 'ID' },
      title: { label: 'Titel' },
      createdAt: { label: 'Gemaakt op' },
      updatedAt: { label: 'Geüpdatet op' },
      file: { label: 'Bestandnaam' },
    },
    create: {
      title: 'Voeg Nieuwe Gebruiksaanwijzing Toe.',
    },
    edit: {
      title: 'Bewerk Gebruiksaanwijzing',
    },
  },
  pageNotFoundMessage: 'Pagina niet gevonden',
  brandingMessage: 'Powered by Theta Vision',
  lppRecord: {
    overview: {
      title: 'LPP Records',
      resultsTitle: 'LPP records zoekresultaten',
    },
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Aangemaakt op' },
      channel: { label: 'Kanaal' },
      value: { label: 'Waarde' },
      dataType: { label: 'Datatype' },
      device: { label: 'Apparaat ID' },
      uplink: { label: 'Uplink ID' },
    },
    dataType: {
      digitalInput: 'Digitale ingang',
      analogInput: 'Analoge ingang',
      relayStatus: 'Relais status',
      temperatureSensor: 'Temperatuursensor',
      errorFlags: 'Foutmeldingen',
    },
  },
  homeDashboard: {
    shipped: 'Verzonden RLCs',
    stocked: 'Voorraad RLCs',
    installed: 'Geïnstalleerde RLCs',
  },
};
